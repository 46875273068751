<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  props: {},
  components: {},
  computed: {
    ...mapState("transporteModule", ["editingPlanificacion"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
    err(){
      return  this.userInputPlan?.fecha_final_ejecucion !== "" ? 
        new Date(this.editingPlanificacion?.tranpsorte_planificacion_detalle
          ?.fecha_final_ejecucion + "T00:00:00") > 
        new Date(this.userInputPlan?.fecha_final_ejecucion_edit + "T00:00:00") 
        : false
    }
  },
  data() {
    return {
      min_date: new Date(),
      max_date: "",
      show_calendar: false,
      calendar_side: "",
      date_selected: "",
      init_date: "Termino",
      userInputPlan: {
        id_planificacion: "0",
        id_cadencia: "2",
        nombre_planificacion: "",
        concurrencia_ejecucion_semanal: "",
        concurrencia_ejecucion_day: "-1",
        concurrencia_ejecucion_month: "-1",
        concurrencia_tiempo: "1",
        inicio_periodo_semanal: null,
        inicio_periodo_day: "-1",
        inicio_periodo_month: "-1",
        hora_inicio_periodo: "08:00:00",
        final_periodo_semanal: null,
        final_periodo_day: "-1",
        final_periodo_month: "-1",
        hora_final_periodo: "12:00:00",
        fecha_inicio_ejecucion: "",
        fecha_final_ejecucion: "",
        fecha_final_ejecucion_edit: null,
        tramos: [],
      },
      diasHash: {
        1: "Lunes",
        2: "Martes",
        3: "Miercoles",
        4: "Jueves",
        5: "Viernes",
        6: "Sabado",
        7: "Domingo",
      },
    };
  },
  created() {
    this.setDataForEditingPlan();
  },
  methods: {
    ...mapActions("transporteModule", ["updateLoadingSpinner"]),
    openCalendarEdit() {
      this.calendar_side = "start-date";
      this.show_calendar = true;
    },
    closeModal() {
      this.$emit("close-modal");
    },
    onDayClick(day) {
      this.date_selected = this.get_date(day.id);
      this.init_date = day.id;
      this.userInputPlan.fecha_final_ejecucion_edit = day.id;
      this.show_calendar = false;
    },
    deleteDate() {
      this.date_selected = "";
      this.init_date = "Término";
      this.userInputPlan.fecha_final_ejecucion_edit = null;
    },
    get_date(day) {
      let dia = day.toString() + "T00:00:00";
      return dia;
    },
    setDataForEditingPlan() {
      Object.assign(this.userInputPlan, {
        nombre_planificacion: this.editingPlanificacion?.nombre_planificacion,
        concurrencia_ejecucion_semanal:
          this.editingPlanificacion?.tranpsorte_planificacion_detalle
            ?.concurrencia_ejecucion_semanal,
        inicio_periodo_semanal:
          this.editingPlanificacion?.tranpsorte_planificacion_detalle
            ?.inicio_periodo_semanal,
        final_periodo_semanal:
          this.editingPlanificacion?.tranpsorte_planificacion_detalle
            ?.final_periodo_semanal,
        id_planificacion: this.editingPlanificacion?.id_planificacion,
        fecha_final_ejecucion: this.editingPlanificacion?.tranpsorte_planificacion_detalle?.fecha_final_ejecucion,fecha_final_ejecucion_edit: null
      });
      if (Object.keys(this.editingPlanificacion).length == 0) return;
      this.init_date =
        this.editingPlanificacion?.tranpsorte_planificacion_detalle?.fecha_final_ejecucion;
      this.min_date = new Date(
          this.editingPlanificacion?.tranpsorte_planificacion_detalle
            ?.fecha_final_ejecucion + "T00:00:00"
      );
    },
    captureDate(date) {
      this.date_mask = moment(date).format("YYYY-MM-DD");
    },
    toUpdate() {
      this.$emit("userInputChange", this.userInputPlan);
    },
  },
  watch: {
    userInputPlan: {
      handler() {
        this.toUpdate();
      },
      deep: true,
    },
  },
};
</script>
<template>
  <div class="row mt-2 mb-2 m-auto">
    <div class="row">
      <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">
        Detalles de planificación
      </h6>
      <div class="row">
        <div class="col-6">
          <p>Nombre: {{ userInputPlan.nombre_planificacion }}</p>
        </div>
        <div class="col-6">
          <p>
            Día del viaje:
            {{ diasHash[userInputPlan.concurrencia_ejecucion_semanal] || "" }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <h6 class="font-main-bold color-main border-bottom pb-1 mb-1">
        Fecha de termino de planificación
      </h6>
    </div>
    <div class="col-11 mx-auto p-0">
      <label class="text-secondary" for="edit-termino">Fecha de termino</label>
      <div class="position-relative">
        <button @click="openCalendarEdit()" class="form-control input-calendar">
          <span>{{ dateFormatymd(init_date) }}</span>
          <div class="icon">
            <font-awesome-icon icon="calendar" />
          </div>
        </button>
        <div
          @click="deleteDate('start-date')"
          v-show="date_selected != ''"
          class="input-calendar-clean"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
    </div>
    <p v-show="err" class="text-danger col-11 mx-auto mt-1 px-0 py-1 text-center rounded bg-danger bg-opacity-25 ">
      La nueva fecha de termino no puede ser <br> menor a la anterior ({{ 
        this.editingPlanificacion?.tranpsorte_planificacion_detalle?.fecha_final_ejecucion     
        }})
    </p>
    <div class="col-11 mx-auto position-relative">
      <div
        class="input-calendar-dropdown"
        :class="calendar_side"
        v-if="show_calendar"
      >
        <v-calendar
          color="blue"
          :min-date="min_date"
          :max-date="max_date"
          :attributes="attributes"
          @dayclick="onDayClick"
        />
        <div class="border-arrow" :class="calendar_side">
          <div class="arrow"></div>
        </div>
      </div>
    </div>
  </div>
</template>
