var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"planificaciones"}},[_c('div',{staticClass:"row align-items-center my-3"},[_vm._m(0),_c('div',{staticClass:"col-12 col-lg-3 d-flex justify-content-end"},[(_vm.planificacionesArr.length > 0)?_c('button',{staticClass:"btn btn-custom btn-custom-color-blue btn-shadow border-round-50",attrs:{"data-bs-toggle":"modal","data-bs-target":"#modal-form-planificaciones"},on:{"click":_vm.onOpenModal}},[_c('font-awesome-icon',{staticClass:"pe-2",attrs:{"icon":"plus"}}),_vm._v(" Crear planificación ")],1):_vm._e()])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('MisPlanificacionesTable',{attrs:{"loading":_vm.loadingSpinner,"planificaciones":_vm.planificacionesArr},on:{"open-modal-create":_vm.onOpenModal,"open-modal-edit":_vm.onOpenModalForEdit}}),(this.pagination.page_count > 1)?_c('div',{staticClass:"users-list__pagination"},[_c('paginate',{attrs:{"page-count":_vm.pagination.page_count,"page-range":_vm.pagination.page_range,"click-handler":_vm.pagination.click_handler,"prev-text":_vm.pagination.prev_text,"next-text":_vm.pagination.next_text,"container-class":_vm.pagination.container_class,"page-class":_vm.pagination.page_class},model:{value:(_vm.pagination.actual_page),callback:function ($$v) {_vm.$set(_vm.pagination, "actual_page", $$v)},expression:"pagination.actual_page"}})],1):_vm._e()],1)]),_c('Modal',{attrs:{"title":_vm.editBool ? 'Editar Planificación' : 'Nueva Planificación',"id-modal":"modal-form-planificaciones"},on:{"close-modal":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CrearPlanificacion',{key:_vm.planificacionKey,on:{"userInputChange":_vm.onUserInputChange,"close-modal":function($event){return _vm.closeModal()}}})]},proxy:true},{key:"footer",fn:function(){return [_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          placement: 'top',
          arrow: true,
          trigger: _vm.planificacionChange() ? 'mouseenter focus' : '',
        }),expression:"{\n          placement: 'top',\n          arrow: true,\n          trigger: planificacionChange() ? 'mouseenter focus' : '',\n        }"}],attrs:{"content":_vm.userInputPlan.createText || ''}},[_c('button',{staticClass:"btn btn-custom-color-blue rounded-pill mw-100",attrs:{"type":"button","disabled":_vm.planificacionChange()},on:{"click":function($event){return _vm.addOrEdit()}}},[_vm._v(" "+_vm._s(_vm.nameAction())+" ")])])]},proxy:true}])}),_c('Modal',{attrs:{"title":"Editar fecha de inscripción","modal-size":"modal-lg","id-modal":"modal-edit-plan-inscripciones"},on:{"close-modal":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ModalEditInscripciones',{key:'edit-plan-inscripciones-' + _vm.planificacionKeyIstr,on:{"userInputChange":_vm.onUserInputChange}})]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-custom-color-blue rounded-pill mw-100",attrs:{"type":"button","disabled":_vm.inscripcionChange()},on:{"click":function($event){return _vm.editInscripcion()}}},[_vm._v(" "+_vm._s(_vm.nameAction())+" ")])]},proxy:true}])}),_c('Modal',{attrs:{"title":"Editar tramos","modal-size":"modal-xl","id-modal":"modal-edit-plan-tramos"},on:{"close-modal":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ModalEditTramos',{key:'edit-plan-tramos-' + _vm.planificacionKeyTramos,on:{"userInputChange":_vm.onUserInputChange}})]},proxy:true},{key:"footer",fn:function(){return [_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
          placement: 'top',
          arrow: true,
          trigger: _vm.tramosChange() ? 'mouseenter focus' : '',
        }),expression:"{\n          placement: 'top',\n          arrow: true,\n          trigger: tramosChange() ? 'mouseenter focus' : '',\n        }"}],attrs:{"content":_vm.userInputPlan.editText || ''}},[_c('button',{staticClass:"btn btn-custom-color-blue rounded-pill mw-100",attrs:{"type":"button","disabled":_vm.tramosChange()},on:{"click":function($event){return _vm.editTramos()}}},[_vm._v(" "+_vm._s(_vm.nameAction())+" ")])])]},proxy:true}])}),_c('Modal',{attrs:{"title":"Editar Fecha","modal-size":"","id-modal":"modal-edit-plan-date"},on:{"close-modal":function($event){return _vm.closeModal()}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ModalDate',{key:'date-' + _vm.planificacionKeyDate,on:{"userInputChange":_vm.onUserInputChange}})]},proxy:true},{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-custom-color-blue rounded-pill mw-100",attrs:{"type":"button","disabled":_vm.dateChange},on:{"click":function($event){return _vm.editDate()}}},[_vm._v(" "+_vm._s(_vm.nameAction())+" ")])]},proxy:true}])}),(_vm.loadingSpinner)?_c('Spinner'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-9"},[_c('h3',{staticClass:"section-head-title ps-0 mb-3 mb-lg-0"},[_vm._v(" En esta seccion podras visualizar las planificaciones creadas o crear nuevas ")])])
}]

export { render, staticRenderFns }