<template>
  <div class="table-container">

    <Loading 
      v-if="loading"
      pixeles="150px"
    />

    <template v-if="!loading">
      <table class="table table-custom table-borderless table-custom-responsive mb-0">
        <thead>
          <tr>
            <th scope="col">Planificación</th>
            <th scope="col" class="text-center">Total de viajes realizados</th>
            <th scope="col" class="text-center">Cantidad de tramos</th>
            <th scope="col" class="text-center">Día del próximo viaje</th>
            <th scope="col" class="text-center">Inicio periodo inscripción</th>
            <th scope="col" class="text-center">Término periodo inscripción</th>
            <th scope="col" class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(p, i) in planificaciones" 
            :key="i">
            <td class="th th-planificacion"> {{ p.nombre_planificacion }} </td>
            <td class="th th-total-viajes-realizados text-center"> {{ viajesRealizados(p) }} </td>
            <td class="th th-cantidad-tramos text-center"> {{ p.transporte_tramo.length }} </td>
            <td class="th th-dia-viaje text-center"> {{ getDiaViaje(p) }} </td>
            <td class="th th-inicio-periodo-inscripcion text-center"> {{ getInicioPeriodoInscripcion(p) }} </td>
            <td class="th th-termino-periodo-inscripcion text-center"> {{ getTerminoPeriodoInscripcion(p) }} </td>
            <!-- Acciones -->
            <td class="th th-accion text-center">
              <div class="dropdown dropdown-custom d-flex justify-content-center">
                  <button 
                    class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                    type="button"
                    data-bs-toggle="dropdown">
                    <font-awesome-icon icon="ellipsis-vertical"/>
                  </button>
                  <ul class="dropdown-menu">
                    <!-- Acciones -->
                    <!--
                      <li>
                        <a 
                          @click="editarPlan(p)"
                          data-bs-toggle="modal" 
                          data-bs-target="#modal-form-planificaciones"
                          class="dropdown-item" 
                          href="javascript:">
                          <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                          Editar
                        </a>
                      </li>
                    -->
                    <li>
                      <a 
                        @click="editarPlan(p ,'fecha')"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-edit-plan-date"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                          Modificar fecha de término
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="editarPlan(p, 'inscripciones')"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-edit-plan-inscripciones"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                        Modificar días de inscripción
                      </a>
                    </li>
                    <li>
                      <a 
                        @click="editarPlan(p, 'tramos')"
                        data-bs-toggle="modal" 
                        data-bs-target="#modal-edit-plan-tramos"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="pen"/>
                        Modificar tramos
                      </a>
                    </li>
                    <li>
                      <a 
                        v-if="isPlanAvailableForDelete(p)"
                        @click="eliminarPlan(p)"
                        class="dropdown-item" 
                        href="javascript:">
                        <font-awesome-icon class="pe-2 color-main" icon="trash-alt"/>
                        Eliminar
                      </a>
                    </li>
                  </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="transporte-container__no-data"  v-if="planificaciones.length==0">
        <img
          class="transporte-container__img-question"
          src="@/assets/img/utilities/question_face.svg"
          alt=""
        />
        <p>Aún no se han registrado planificaciones</p>
        <button 
          class="tramos-add__boton" 
          @click="openModalCreate()"
          data-bs-toggle="modal" 
          data-bs-target="#modal-form-planificaciones"
        >
          + Crear planificación
        </button>
      </div>
      <Question
        v-if="open_question_modal"
        :msg="question_modal_msg"
        :hideCancel="false"
        @cancel="cancelQuestion"
        @accept="acceptQuestion"
      />
      <Status
        v-if="open_modal_status"
        :msg="modal_status_msg"
        :status="modal_status"
        @close="closeStatus"
      />
    </template>
  </div>
</template>

<script>
  import Question from "../Modales/Question.vue";
  import Status from "../Modales/Status.vue";
  import { mapActions, mapState } from "vuex";
  import moment from "moment";
  import 'moment/locale/es';

  //FF
  import Loading from "@/components/Loading.vue";

  export default {
    components: {
      Question, 
      Status, 

      //FF
      Loading
    },

    props: {
      planificaciones: {
        type: Array,
      },
      loading: {
        type: Boolean,
        required: false
      }
    },

    data(){
      return {
        show_no_result: false,
        dia: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
        show_spinner: false,

        open_question_modal: false,
        question_modal_msg: '',

        open_modal_status: false,
        modal_status_msg: "",
        modal_status: true,

        id_delete:'',
      };
    },

    computed: {
      ...mapState("transporteModule", ["planificaciones_list", "viajesEnCurso_list"]),
    },

    async created() {
      this.show_spinner = true;
      const data = {
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa, 
        tipoRango: "0",
        fechaInicio: "",
        fechaFin: "",
        limit: "1000",
        fechaId: "",
      };
      await this.getViajesEnCurso(data);
      this.show_spinner = false;
    },

    watch:{
      planificaciones() {
        if(this.planificaciones.length > 0) this.show_no_result = false;
        else this.show_no_result = true;
      }
    },

    methods: {
      ...mapActions("transporteModule", [
        "getViajesEnCurso", 
        "deletePlanificacionAction", 
        "setEditingPlanAction"
      ]),

      openModalCreate() {
        this.$emit("open-modal-create");
      },

      viajesRealizados(plan){
        const hoy = moment();
        const viajesRealizados = plan.transporte_tramo.filter(t => hoy > moment(t.fecha_viaje + 'T' + t.hora_viaje));
        return viajesRealizados.length;
      },

      getDiaViaje(p) {
        const date = this._getTripDateFor(p, "fecha_viaje", "hora_viaje");
        return date === "-" ? "Planificación terminada" : date;
      },

      
      getInicioPeriodoInscripcion(p){
        return this._getTripDateFor(p, "fecha_inicio_inscripcion", "hora_inicio_inscripcion");
      },
      
      getTerminoPeriodoInscripcion(p) {
        return this._getTripDateFor(p, "fecha_fin_inscripcion", "hora_fin_inscripcion");
      },
      
      _getTripDateFor(p, dateFieldname, timeFieldname) {
        const ahora = moment().valueOf();
        const ultimoViaje = p.transporte_tramo[p.transporte_tramo.length - 1];
        
        const fechaUltimoViaje = moment(`${ultimoViaje.fecha_viaje}T${ultimoViaje.hora_viaje}`).valueOf();
        if (ahora > fechaUltimoViaje) {
          return "-";
        }
        if (p.transporte_planificacion_cadencia.nombre_cadencia == "Semanal") {
          const tramo = this._findNextTrip(p.transporte_tramo);
          if (!tramo) return "Sin información";
          const [date, time]  = [tramo[dateFieldname], tramo[timeFieldname]];
          
          const momentDate = moment(`${date}T${time}`);
          return momentDate.format('D MMM YYYY HH:mm A');
        } else {
          return "Por definir";
        }
      },

      _findNextTrip(tramos) {
        const hoy = moment();
        const viajesAunNoRealizados = tramos.
          filter(t => hoy <= moment(`${t.fecha_fin_inscripcion}T${t.hora_fin_inscripcion}`));
        viajesAunNoRealizados.sort((t1, t2) => 
          moment(t1.fecha_fin_inscripcion + 'T' + t1.hora_fin_inscripcion) - 
          moment(t2.fecha_fin_inscripcion + 'T' + t2.hora_fin_inscripcion)
        );

        return viajesAunNoRealizados[0];
      },




      isPlanAvailableForDelete(plan) {
        if (plan.transporte_planificacion_cadencia.nombre_cadencia == "Semanal") {
          const nextTrip = this._findNextTrip(plan.transporte_tramo);
          if (!nextTrip) return true; // se puede borrar la plan si no hay un "próximo viaje", i.e. si plan está terminada
          else return this._hasRegistrationForNextTripStarted(nextTrip);
        } else {
          return false;
        }
      },


      _hasRegistrationForNextTripStarted(tramo) {
        const hoy = moment();
        const registrationDate = moment(`${tramo.fecha_inicio_inscripcion}T${tramo.hora_inicio_inscripcion}`);
        return (registrationDate >= hoy);
      },

      editarPlan(p, type = ""){
        this.setEditingPlanAction(p);
        this.$emit("open-modal-edit", type);
      },

      eliminarPlan(planificacion) {
        if (this.tramosActivos(planificacion.id_planificacion) == true) {
          this.open_modal_status = true;
          this.modal_status_msg = "Lo sentimos, no se puede eliminar una planificación con tramos activos.";
          this.modal_status = false;
        } else {
          this.id_delete = planificacion.id_planificacion;
          this.open_question_modal = true;
          this.question_modal_msg = "¿Estás seguro(a) de eliminar esta planificación?";
          this.modal_status = true;
        }
      },

      // Recorre tramos y verifica si esta activo (ahora esto se hace por response de ws)
      tramosActivos(id_planificacion) {
        let tramos_activos = 0;
        this.viajesEnCurso_list.forEach(plan => {
          if (plan.transporte_planificacion.id_planificacion == id_planificacion && plan.estado == "Activa") {
            tramos_activos++;
          }
        });
        return tramos_activos > 0 ? true : false;
      },

      // Eliminar planificacion
      async acceptQuestion() {
        this.show_spinner = true;
        const payload = {
          idPlanificacion: this.id_delete,
          idEmpresa: this.$ls.get("user").id_empresa_fk,
          idUsuario: this.$ls.get("user").id_usuario
        };
        const deletedPlan = await this.deletePlanificacionAction(payload);
        this.show_spinner = false;
        this.open_question_modal = false;  

        if (deletedPlan) {        
          if(deletedPlan.nombre_planificacion == 'in_use') {
            this.toastDeletePlanErrorActiveTramos();
          } else {
            this.toastDeletePlanSuccess();
          }
        } else {
          this.toastDeletePlanErrorUnknown();
        }
      },

      // Cancela eliminar
      cancelQuestion() {
        this.open_question_modal = false;
      },

      // Termina eliminar
      closeStatus() {
        this.open_modal_status = false;
      },

      toastDeletePlanErrorActiveTramos() {
        this.$toast.open({
          type: 'error',
          message: 'Lo sentimos, no es posible eliminar una planificación con tramos activos.',
          position: 'top-right',
          duration: 5000,
        });
      },
      toastDeletePlanErrorUnknown() {
        this.$toast.open({
          type: 'error',
          message: 'Ocurrió un error al eliminar la planificación. Por favor inténtalo nuevamente.',
          position: 'top-right',
          duration: 5000,
        });
      },
      toastDeletePlanSuccess() {
        this.$toast.open({
          type: 'success',
          message: 'Planificación eliminada correctamente.',
          position: 'top-right',
          duration: 5000,
        });
      }

    },
  };
</script>
