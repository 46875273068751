<template>
  <section id="planificaciones">
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          En esta seccion podras visualizar las planificaciones creadas o crear
          nuevas
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <button
          v-if="planificacionesArr.length > 0"
          @click="onOpenModal"
          data-bs-toggle="modal"
          data-bs-target="#modal-form-planificaciones"
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50"
        >
          <font-awesome-icon icon="plus" class="pe-2" />
          Crear planificación
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <MisPlanificacionesTable
          :loading="loadingSpinner"
          :planificaciones="planificacionesArr"
          @open-modal-create="onOpenModal"
          @open-modal-edit="onOpenModalForEdit"
        />

        <div
          class="users-list__pagination"
          v-if="this.pagination.page_count > 1"
        >
          <paginate
            v-model="pagination.actual_page"
            :page-count="pagination.page_count"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          >
          </paginate>
        </div>
      </div>
    </div>

    <!--Crear/Edtar planificación-->
    <Modal
      :title="editBool ? 'Editar Planificación' : 'Nueva Planificación'"
      @close-modal="closeModal()"
      id-modal="modal-form-planificaciones"
    >
      <template v-slot:body>
        <CrearPlanificacion
          @userInputChange="onUserInputChange"
          @close-modal="closeModal()"
          :key="planificacionKey"
        />
      </template>
      <template v-slot:footer>
        <span
          :content="userInputPlan.createText || ''"
          v-tippy="{
            placement: 'top',
            arrow: true,
            trigger: planificacionChange() ? 'mouseenter focus' : '',
          }"
        >
          <button
            type="button"
            class="btn btn-custom-color-blue rounded-pill mw-100"
            :disabled="planificacionChange()"
            @click="addOrEdit()"
          >
            {{ nameAction() }}
          </button>
        </span>
      </template>
    </Modal>
    <!--Edit inscripción-->
    <Modal
      title="Editar fecha de inscripción"
      modal-size="modal-lg"
      @close-modal="closeModal()"
      id-modal="modal-edit-plan-inscripciones"
    >
      <template v-slot:body>
        <ModalEditInscripciones
          :key="'edit-plan-inscripciones-' + planificacionKeyIstr"
          @userInputChange="onUserInputChange"
        />
      </template>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-custom-color-blue rounded-pill mw-100"
          @click="editInscripcion()"
          :disabled="inscripcionChange()"
        >
          {{ nameAction() }}
        </button>
      </template>
    </Modal>
    <!--Edit tramos-->
    <Modal
      title="Editar tramos"
      @close-modal="closeModal()"
      modal-size="modal-xl"
      id-modal="modal-edit-plan-tramos"
    >
      <template v-slot:body>
        <ModalEditTramos
          @userInputChange="onUserInputChange"
          :key="'edit-plan-tramos-' + planificacionKeyTramos"
        />
      </template>
      <template v-slot:footer>
        <span
          :content="userInputPlan.editText || ''"
          v-tippy="{
            placement: 'top',
            arrow: true,
            trigger: tramosChange() ? 'mouseenter focus' : '',
          }"
        >
          <button
            type="button"
            class="btn btn-custom-color-blue rounded-pill mw-100"
            @click="editTramos()"
            :disabled="tramosChange()"
          >
            {{ nameAction() }}
          </button>
        </span>
      </template>
    </Modal>
    <!--Edit fecha-->
    <Modal
      title="Editar Fecha"
      @close-modal="closeModal()"
      modal-size=""
      id-modal="modal-edit-plan-date"
    >
      <template v-slot:body>
        <ModalDate
          @userInputChange="onUserInputChange"
          :key="'date-' + planificacionKeyDate"
        ></ModalDate>
      </template>
      <template v-slot:footer>
        <!--Todo:editDate hacer la responsibidad de la fecha en el input -->
        <button
          type="button"
          class="btn btn-custom-color-blue rounded-pill mw-100"
          @click="editDate()"
          :disabled="dateChange"
        >
          {{ nameAction() }}
        </button>
      </template>
    </Modal>
    <Spinner v-if="loadingSpinner" />
  </section>
</template>

<script>
import CrearPlanificacion from "./CrearPlanificacion.vue";
import MisPlanificacionesTable from "./MisPlanificacionesTable.vue";
import { mapActions, mapState } from "vuex";
import Modal from "../../components/layout/modal.vue";
import ModalDate from "../../components/Transporte/ModalEditPlanDate.vue";
import ModalEditInscripciones from "@/components/Transporte/ModalEditPlanInscripciones.vue";
import ModalEditTramos from "@/components/Transporte/ModalEditPlanTramos.vue";
import Spinner from "../Spinner.vue";
export default {
  components: {
    CrearPlanificacion,
    MisPlanificacionesTable,
    Modal,
    ModalDate,
    ModalEditInscripciones,
    ModalEditTramos,
    Spinner,
  },
  data() {
    return {
      open_modal: false,
      modal_type: "",
      editBool: false,
      planificacionKey: 0,
      planificacionKeyDate: 0,
      planificacionKeyIstr: 0,
      planificacionKeyTramos: 0,

      pagination: {
        actual_page: 1,
        page_count: 0,
        page_range: 3,
        click_handler: this.changePage,
        prev_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text:
          '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class: "pagination-item",
      },
      planificaciones_limit: "10",
      userInputPlan: {},

      //FF
      loading: true,
    };
  },
  computed: {
    ...mapState("transporteModule", [
      "planificaciones_list",
      "editingPlanificacion",
      "planificacionesPaginationArr",
      "planificacionesArr",
      "loadingSpinner"
    ]),
    dateChange() {
      if (this.modal_type !== "fecha") return true;
      const { fecha_final_ejecucion, fecha_final_ejecucion_edit } =
        this.userInputPlan;
      if (
        fecha_final_ejecucion_edit === null ||
        fecha_final_ejecucion_edit === undefined
      )
        return true;
      if (fecha_final_ejecucion_edit < fecha_final_ejecucion) return true;
      return false;
    },
  },
  mounted() {
    this.searchPlanificaciones();
  },
  methods: {
    ...mapActions("transporteModule", [
      "createPlanificacionAction",
      "editPlanificacionAction",
      "editPlanificacionDateAction",
      "editPlanificacionTramosAction",
      "editPlanificacionInscripcionAction",
      "cleanEditingPlanAction",
      "getPlanificacionById",
      "getPlanificacionesPagination",
      "getPlanificacionesPaginated",
      "updateLoadingSpinner"
    ]),
    nameAction() {
      if (this.editBool == true) return "Guardar";
      else return "Añadir";
    },
    // Planificaciones
    async searchPlanificaciones() {
      this.updateLoadingSpinner(true);
      await this.getPlanificacionesPages();
      if (this.planificacionesPaginationArr.length > 0) {
        this.pagination.actual_page = 1;
        await this.getPlanificacionesByPage(this.pagination.actual_page);
      }
      this.updateLoadingSpinner(false);
      
    },
    planificacionChange() {
      if (this.modal_type !== "") return true;
      return this.userInputPlan.create;
    },
    inscripcionChange() {
      if (this.modal_type !== "inscripciones") return true;
      return this.userInputPlan.edit;
    },
    tramosChange() {
      if (this.modal_type !== "tramos") return true;
      return this.userInputPlan.edit;
    },
    async getPlanificacionesPages() {
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        limite: this.planificaciones_limit,
      };
      await this.getPlanificacionesPagination(data_);
      this.pagination.page_count = this.planificacionesPaginationArr.length;
    },

    async getPlanificacionesByPage(page) {
      this.loading = true;
      let data_ = {
        id_empresa: this.$ls.get("user").empresa[0].id_empresa,
        id_planificacion:
          this.planificacionesPaginationArr[page - 1].planificaciones,
      };
      await this.getPlanificacionesPaginated(data_);
      this.loading = false;
    },

    async changePage(page) {
      this.loading = true;
      this.pagination.actual_page = page;
      await this.getPlanificacionesByPage(this.pagination.actual_page);
      this.loading = false;
    },

    validateWeekdayOrder() {
      const viaje = this.userInputPlan.concurrencia_ejecucion_semanal;
      const inicio = this.userInputPlan.inicio_periodo_semanal;
      const cierre = this.userInputPlan.final_periodo_semanal;
      // El dia del viaje no puede estar entre inicio y cierre
      if (cierre < inicio && inicio < viaje) return false;
      if (inicio < viaje && viaje < cierre) return false;
      if (viaje < cierre && cierre < inicio) return false;
      // if (viaje < inicio && inicio == cierre) return false;

      return true;
    },

    async addOrEdit() {
      if (this.validatePlanFields() == false) {
        this.toastPlanificacionValidationIncomplete();
        return;
      }

      if (this.validateWeekdayOrder() == false) {
        this.toastWeekdaysInvalid();
        return;
      }
      
      this.updateLoadingSpinner(true);
      if (this.editBool === true) {
        await this.editPlanificacion();
      } else {
        await this.addPlanificacion();
      } 
      
      this.updateLoadingSpinner(false);
    },

    async addPlanificacion() {
      this.open_modal = false;
      this.loading = true;

      if (this.userInputPlan.id_cadencia == "1") {
        this.userInputPlan.concurrencia_ejecucion_semanal = "-1";
        this.userInputPlan.inicio_periodo_semanal = "-1";
        this.userInputPlan.final_periodo_semanal = "-1";
      }

      const {
        concurrencia_ejecucion_day,
        concurrencia_ejecucion_month,
        concurrencia_ejecucion_semanal,
        concurrencia_tiempo,
        fecha_final_ejecucion,
        fecha_inicio_ejecucion,
        final_periodo_day,
        final_periodo_month,
        final_periodo_semanal,
        hora_final_periodo,
        hora_inicio_periodo,
        id_cadencia,
        id_planificacion,
        inicio_periodo_day,
        inicio_periodo_month,
        inicio_periodo_semanal,
        nombre_planificacion,
        tramos
      } = this.userInputPlan;

      const payload = {
        planificacion: {
          concurrencia_ejecucion_day,
          concurrencia_ejecucion_month,
          concurrencia_ejecucion_semanal,
          concurrencia_tiempo,
          fecha_final_ejecucion,
          fecha_inicio_ejecucion,
          final_periodo_day,
          final_periodo_month,
          final_periodo_semanal,
          hora_final_periodo,
          hora_inicio_periodo,
          id_cadencia,
          id_planificacion,
          inicio_periodo_day,
          inicio_periodo_month,
          inicio_periodo_semanal,
          nombre_planificacion,
          tramos
        },
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };
      const res = await this.createPlanificacionAction(payload);
      if (res) {
        this.clean();
        this.closeModal();
        this.toastPlanificacionCreateSuccess();
      } else {
        this.toastPlanificacionCreateError();
      }
      this.loading = false;
      this.closeModal();
    },

    async editPlanificacion() {
      this.loading = true;
      const payload = {
        planificacion: {
          id_planificacion: this.userInputPlan.id_planificacion,
          tramos: this.userInputPlan.tramos,
        },
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };
      const res = await this.editPlanificacionAction(payload);
      if (res) {
        this.clean();
        this.open_modal = false;
        this.toastPlanificacionEditSuccess();
      } else {
        this.toastPlanificacionEditError();
      }
      this.loading = false;
      this.closeModal();
    },
    editTramos() {
      this.updateLoadingSpinner(true);
      const {
        id_planificacion,
        tramos,
        fecha_inicio_ejecucion,
        fecha_final_ejecucion,
        concurrencia_tiempo,
        concurrencia_ejecucion_semanal,
        concurrencia_ejecucion_day,
        concurrencia_ejecucion_month,
      } = this.userInputPlan;

      const payload = {
        planificacion: {
          id_planificacion,
          tramos,
          fecha_inicio_ejecucion,
          fecha_final_ejecucion,
          concurrencia_tiempo,
          concurrencia_ejecucion_semanal,
          concurrencia_ejecucion_day,
          concurrencia_ejecucion_month,
        },
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };
      this.editPlanificacionTramosAction(payload)
        .then(() => {
          this.toastPlanificacionEditSuccess();
          this.closeModal();
        })
        .catch(() => {
          this.toastPlanificacionEditError();
        })
        .finally(() => {
          this.updateLoadingSpinner(false);
        });
    },

    editDate() {
      this.updateLoadingSpinner(true);
      const { id_planificacion, fecha_final_ejecucion_edit } =
        this.userInputPlan;
      if (!fecha_final_ejecucion_edit) {
        this.toastPlanificacionEditError();
        this.updateLoadingSpinner(false);
        return;
      }
      const payload = {
        planificacion: {
          id_planificacion,
          fecha_final_ejecucion: fecha_final_ejecucion_edit,
        },
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
      };
      this.editPlanificacionDateAction(payload)
        .then(() => {
          this.closeModal();
          this.toastPlanificacionEditSuccess();
        })
        .catch(() => {
          this.toastPlanificacionEditError();
        })
        .finally(() => {
          this.updateLoadingSpinner(false);
        });
    },
    editInscripcion() {
      this.updateLoadingSpinner(true);
      const {
        id_planificacion,
        id_cadencia,
        hora_inicio_periodo,
        hora_final_periodo,
        inicio_periodo_day,
        final_periodo_day,
        inicio_periodo_semanal,
        final_periodo_semanal,
        inicio_periodo_month,
        final_periodo_month,
        edit,
      } = this.userInputPlan;
      if (edit) {
        this.toastPlanificacionEditError();
        this.updateLoadingSpinner(false);
        return;
      }
      const payload = {
        idEmpresa: this.$ls.get("user").id_empresa_fk,
        idUsuario: this.$ls.get("user").id_usuario,
        planificacion: {
          id_planificacion,
          id_cadencia,
          hora_inicio_periodo,
          hora_final_periodo,
          inicio_periodo_day,
          final_periodo_day,
          inicio_periodo_semanal,
          final_periodo_semanal,
          inicio_periodo_month,
          final_periodo_month,
        },
      };
      this.editPlanificacionInscripcionAction(payload)
        .then(() => {
          this.closeModal();
          this.toastPlanificacionEditSuccess();
        })
        .catch(() => {
          this.toastPlanificacionEditError();
        })
        .finally(() => {
          this.updateLoadingSpinner(false);
        });
    },

    clean() {
      this.cleanEditingPlanAction();
      (this.editBool = false), (this.userInputPlan = {});
    },

    closeModal() {
      this.planificacionKey += 1;
      this.editBool = false;
      this.open_modal = false;
      this.modal_type = "";
      this.clean();
      document
        .querySelectorAll(".modal-custom-close")
        .forEach((el) => el.click());
    },

    validatePlanFields() {
      if (this.editBool == false) {
        if (this.userInputPlan.id_cadencia == "2") {
          return (
            this.userInputPlan.nombre_planificacion != "" &&
            this.userInputPlan.concurrencia_ejecucion_semanal != null &&
            this.userInputPlan.inicio_periodo_semanal != null &&
            this.userInputPlan.final_periodo_semanal != null &&
            this.userInputPlan.fecha_inicio_ejecucion != "" &&
            this.userInputPlan.fecha_final_ejecucion != "" &&
            this.userInputPlan.tramos.length > 0
          );
        } else if (this.userInputPlan.id_cadencia == "1") {
          return (
            this.userInputPlan.nombre_planificacion != "" &&
            this.userInputPlan.fecha_inicio_ejecucion != "" &&
            this.userInputPlan.fecha_final_ejecucion != "" &&
            this.userInputPlan.tramos.length > 0
          );
        }
      } else {
        return true;
      }
    },

    onUserInputChange(userInputObj) {
      this.userInputPlan = userInputObj;
    },
    addKey(key) {
      const keys = {
        fecha: (this.planificacionKeyDate += 1),
        inscripciones: (this.planificacionKeyIstr += 1),
        tramos: (this.planificacionKeyTramos += 1),
        "": (this.planificacionKey += 1),
      };
      keys[key];
    },
    onOpenModal() {
      this.open_modal = true;
      this.editBool = false;
      this.addKey(this.modal_type);
      this.modal_type = "";
    },
    onOpenModalForEdit(type) {
      this.open_modal = true;
      this.editBool = true;
      this.modal_type = type;
      this.addKey(this.modal_type);
    },

    toastPlanificacionCreateSuccess() {
      this.$toast.open({
        message: `Planificación creada correctamente.`,
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastPlanificacionCreateError() {
      this.$toast.open({
        message: `Ha ocurrido un problema. Por favor inténtalo nuevamente.`,
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastPlanificacionEditSuccess() {
      this.$toast.open({
        message: `Planificación editada correctamente.`,
        type: "success",
        duration: 5000,
        position: "top-right",
      });
    },
    toastPlanificacionEditError() {
      this.$toast.open({
        message: `Ha ocurrido un problema. Por favor inténtalo nuevamente.`,
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastPlanificacionValidationIncomplete() {
      this.$toast.open({
        message: "Por favor completa los campos que faltan.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
    toastWeekdaysInvalid() {
      this.$toast.open({
        message:
          "El día del viaje no puede quedar entre el día de inicio de inscripción\
          y el día de término de inscripción.",
        type: "error",
        duration: 5000,
        position: "top-right",
      });
    },
  },
};
</script>
