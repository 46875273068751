<script>
export default {
    props: {
        idModal:{
            type: String,
            required: true
        },
        title: {
            type: String,
            required: true,
            default: "Modal"
        },
        backButton: {
            type: Boolean,
            required: false,
            default: true
        },
        modalSize: {
            type: String,
            required: false,
            default: "modal-xl"
        }
    },
    data() {
        return {
            open_modal: false,
        }
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
    }
}
</script>
<template>
    <div class="modal modal-custom fade" :id="idModal" tabindex="-1">
        <div class="modal-dialog" :class="modalSize">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title font-main-bold color-main fs-5">
                        {{ title }}
                    </h1>
                    <button type="button" class="modal-custom-close" data-bs-dismiss="modal" @click="closeModal()"
                        aria-label="Close">
                        <i class="fa-solid fa-xmark"></i>
                    </button>
                </div>
                <div  class="modal-body">
                    <div class="row"> 
                        <slot name="body"></slot>
                    </div>
                </div>
                <footer class="modal-footer">
                    <button v-show="backButton"
                        type="button" 
                        class="btn btn-custom-color-white rounded-pill mw-100" 
                        @click="closeModal()"
                        data-bs-dismiss="modal">
                        <i class="fa-solid fa-angle-left pe-1"></i>
                        Volver
                    </button>
                    <slot name="footer"></slot>
                </footer>
            </div>
        </div>
    </div>
</template>